import React from 'react';
import { DonationHero, DonationDescript } from '../components';
import Layout from '../components/layout';

const TamogatasPage = () => {
  return (
    <Layout
      seo={{
        title: 'Támogass minket',
        description: 'A Fortély-csapat'
      }}
    >
      <DonationHero />
      <DonationDescript />
    </Layout>
  );
};

export default TamogatasPage;
